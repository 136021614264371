import { render, staticRenderFns } from "./logistics-except.vue?vue&type=template&id=7f829a52&scoped=true"
import script from "./logistics-except.ts?vue&type=script&lang=ts&external"
export * from "./logistics-except.ts?vue&type=script&lang=ts&external"
import style0 from "./logistics-except.scoped.scss?vue&type=style&index=0&id=7f829a52&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f829a52",
  null
  
)

export default component.exports