import { render, staticRenderFns } from "./exception-report.vue?vue&type=template&id=33b16047&scoped=true"
import script from "./exception-report.ts?vue&type=script&lang=ts&external"
export * from "./exception-report.ts?vue&type=script&lang=ts&external"
import style0 from "./exception-report.scoped.scss?vue&type=style&index=0&id=33b16047&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b16047",
  null
  
)

export default component.exports